<template>

  <div>
    <form
      method="POST"
      action="/cms/communications/send-group-message"
      v-if="true"
      style="text-align: left"
    >
      <Button
        type="submit"
        class="text-left"
        :disabled="selectedContacts.length < 1"
        block
        ghost
        color="primary"
      >
        <v-icon size="18px">fal fa-paper-plane</v-icon> &nbsp; {{ buttonText }}
      </Button>
      <div v-for="id in personIdsOfSelectedKids" :key="id">
        <input type="hidden" name="selectedKids[]" :value="id" />
      </div>
    </form>
    <div v-else>
      <a class="info radius tiny button" data-reveal-id="sendMessageModal"
        ><i class="fal fa-envelope"></i> Send Email</a
      >
      <div
        id="sendMessageModal"
        class="reveal-modal small"
        data-reveal
        aria-labelledby="modalTitle"
        aria-hidden="true"
        role="dialog"
      >
        <p class="lead">Sending messages</p>
        <p>Please select at least one contact to send a message</p>
        <p>
          We cannot allow you to message all your contacts, please either select
          choose a class or camp register to message all contacts in a class or
          use our Booking Status filter
        </p>
        <img
          style="margin: 0 auto; display: block"
          src="/assets-dev/images/app/messaging-contacts.gif"
          alt="Send Messages"
        />
        <a class="close-reveal-modal" aria-label="Close">&#215;</a>
      </div>
    </div>


    <br>
      <Button
        type="submit"
        class="text-left"
        :disabled="!attachmentsButtonEnabled"
        block
        ghost
        color="primary"
        @click="sendEmail"
      >
        <v-icon size="18px">fal fa-paperclip</v-icon> &nbsp; {{ buttonText }}
      </Button>
      <small>Emails with attachments are temporarily limited to 1000 recipients.</small>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ContactsSendEmailForm',
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['selectedContacts', 'settings']),
    contactIdsOfSelectedKids() {
      return this.selectedContacts.map((item) => item.contactId);
    },
    personIdsOfSelectedKids() {
      return this.selectedContacts.map((item) => item.childId);
    },
    buttonText() {
      return `Send ${
        this.selectedContacts.length > 0 ? this.selectedContacts.length : ''
      } Email`;
    },
    subdomain() {
      return this.settings.subdomain
    },
    UFE_URL() {
      return this.settings.ufe_url
    },
    // we are currently limiting the number of email attachment recipients to 1000.
    // going much over a thousand has the potential to generate a url which is over 
    // 8000 characters long, which will break during the redirect to the UFE due to cloudfront
    // quotas - https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/cloudfront-limits.html#limits-general
    // it is not a big deal if a user gets around this somehow, but they will eventually hit a cloudfront error.

    attachmentsButtonEnabled(){
      return (this.selectedContacts.length >= 1 && this.selectedContacts.length <= 1000);
    },
  },
  methods: {
    sendEmail() {
      const encodedContactIds = encodeURIComponent(this.contactIdsOfSelectedKids.join(','));
      const encodedUrl = this.UFE_URL + 'club/' + this.subdomain + '/cms/messaging/send?contactId=' + encodedContactIds;
      window.location.href = encodedUrl;
    }

  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  updated() {},
};
</script>

<style scoped></style>
